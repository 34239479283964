<template>
	<div>
		<!-- <period></period> -->
		<section class="pg_content">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h1 class="s_elem-has-icon-left a_fill_default">
								KPI Dashboard
								<icon :icon-id="'icon_kpi'"></icon>
							</h1>
						</div>
						<div class="pg_header_description">
							<p>De belangrijkste indicatoren van {{ selected.vestigingNaam }}</p>
						</div>
					</header>
					<hr />
					<div class="pg_kpis_circle">
						<statistics-badge-circle
							title="Aantal leden"
							soort="aantal-leden"
						></statistics-badge-circle>
						<statistics-badge-circle
							title="Aanmeldingen"
							soort="aantal-inschrijvingen"
						></statistics-badge-circle>
						<statistics-badge-circle
							title="Afmeldingen"
							soort="aantal-afmeldingen"
							:invert-change-colors="true"
						></statistics-badge-circle>
						<statistics-badge-circle
							title="Bezoeken"
							soort="aantal-bezoeken"
						></statistics-badge-circle>
					</div>
					<hr />
				</div>
			</div>
		</section>

		<section class="pg_content a_margin-top-60">
			<registration-targets></registration-targets>
		</section>

		<section class="pg_content a_margin-top-60">
			<cancellation-targets></cancellation-targets>
		</section>

		<section class="pg_content a_margin-top-60">
			<check-ins></check-ins>
		</section>

		<section class="pg_content a_margin-top-60">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_top">
							<div class="pg_header_top_left">
								<div class="pg_header_heading">
									<h2>Groepslessen</h2>

									<!-- <infoDropdown>
                                        Vestibulum id ligula porta felis euismod semper.
                  </infoDropdown>-->
								</div>
								<div class="pg_header_description">
									<p>Een overzicht van de totale groepsles bezetting van vandaag.</p>
								</div>
							</div>
							<div class="pg_header_top_right">
								<router-link
									to="/groepslessen"
									class="button v_ghost_brand_primary v_has-icon-right v_icon-size-10 v_smallest a_margin-left-10 show-for-medium-portrait"
									>Naar groepslessen overzicht
									<icon :icon-id="'icon_arrow-right'"></icon>
								</router-link>
							</div>
						</div>
					</header>

					<!--
                        Indicate the amount still LEFT, not the amount progressed
          -->
					<div class="pg_grid_progress">
						<div class="pg_grid_progress_top">
							<div class="pg_grid_progress_top_left">
								<div class="pg_chart_progress">
									<div
										class="pg_chart_progress_amount-left"
										:style="{
											width:
												(statistics.lesBezettingsgraad.value > 0
													? 100 - Number(statistics.lesBezettingsgraad.value)
													: 100) + '%',
										}"
									></div>
								</div>
							</div>
							<div class="pg_grid_progress_top_right">
								<div class="pg_grid_progress_current-number">
									{{ statistics.lesBezettingsgraad.value }}%
								</div>
								<div class>
									<span class="pg_grid_item_description">vorige periode</span>
									{{ statistics.lesBezettingsgraad.prev }}%
								</div>
							</div>
						</div>
						<div class="pg_grid_progress_bottom">
							<div class="pg_grid_progress_bottom_left">
								<div class="pg_grid_progress_number">
									<kpi-value type="aantal-unieke-les-bezoeken" />
								</div>
								<div class="pg_grid_progress_description">plekken gereserveerd</div>
							</div>
							<div class="pg_grid_progress_bottom_right">
								<div class="pg_grid_progress_description">nog beschikbare plekken</div>
								<div class="pg_grid_progress_number">
									<kpi-value type="aantal-vrije-les-plekken" />
								</div>
							</div>
						</div>
					</div>

					<hr />
				</div>
			</div>
		</section>

		<section class="pg_content a_margin-top-60">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_top">
							<div class="pg_header_top_left">
								<div class="pg_header_heading">
									<h2>Coaches informatie</h2>

									<!-- <infoDropdown>Vestibulum id ligula porta felis euismod semper.</infoDropdown> -->
								</div>
								<div class="pg_header_description">
									<p>Een overzicht van de belangrijkste cijfers onder jouw trainers.</p>
								</div>
							</div>
							<div class="pg_header_top_right">
								<router-link
									to="/coaches"
									class="button v_ghost_brand_primary v_has-icon-right v_icon-size-10 v_smallest a_margin-left-10 show-for-medium-portrait"
									>Naar coaches overzicht
									<icon :icon-id="'icon_arrow-right'"></icon>
								</router-link>
							</div>
						</div>
					</header>

					<div class="pg_grid" data-s-amount="3">
						<div class="pg_grid_item" data-s-amount-item>
							<div class="pg_grid_item_heading">
								<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
									Intakes
									<icon :icon-id="'icon_intake'"></icon>
								</div>
							</div>
							<div class="pg_grid_item_content">
								<div class="pg_grid_item_content_left">
									<div class="pg_grid_item_number">
										{{ statistics.actieAantalIntakes.value }}
									</div>
								</div>
								<div class="pg_grid_item_content_right">
									<div class="pg_grid_item_description">
										Aantal intakes die afgenomen zijn of ingepland
									</div>
								</div>
							</div>
						</div>
						<div class="pg_grid_item" data-s-amount-item>
							<div class="pg_grid_item_heading">
								<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
									Metingen
									<icon :icon-id="'icon_meting'"></icon>
								</div>
							</div>
							<div class="pg_grid_item_content">
								<div class="pg_grid_item_content_left">
									<div class="pg_grid_item_number">
										{{ statistics.actieAantalMetingen.value }}
									</div>
								</div>
								<div class="pg_grid_item_content_right">
									<div class="pg_grid_item_description">
										Aantal metingen die afgenomen zijn of ingepland
									</div>
								</div>
							</div>
						</div>
						<div class="pg_grid_item" data-s-amount-item>
							<div class="pg_grid_item_heading">
								<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
									Doelen
									<icon :icon-id="'icon_goal'"></icon>
								</div>
							</div>
							<div class="pg_grid_item_content">
								<div class="pg_grid_item_content_left">
									<div class="pg_grid_item_number">
										{{ statistics.actieAantalDoelen.value }}
									</div>
								</div>
								<div class="pg_grid_item_content_right">
									<div class="pg_grid_item_description">
										Aantal doelen die bepaald zijn of ingepland
									</div>
								</div>
							</div>
						</div>
						<div class="pg_grid_item" data-s-amount-item>
							<div class="pg_grid_item_heading">
								<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
									Trainingsschema’s
									<icon :icon-id="'icon_trainingsschema'"></icon>
								</div>
							</div>
							<div class="pg_grid_item_content">
								<div class="pg_grid_item_content_left">
									<div class="pg_grid_item_number">
										{{ statistics.actieAantalSchemas.value }}
									</div>
								</div>
								<div class="pg_grid_item_content_right">
									<div class="pg_grid_item_description">
										Aantal trainingsschema's die opgesteld zijn of ingepland
									</div>
								</div>
							</div>
						</div>
						<div class="pg_grid_item" data-s-amount-item>
							<div class="pg_grid_item_heading">
								<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
									Groepslessen
									<icon :icon-id="'icon_groepsles'"></icon>
								</div>
							</div>
							<div class="pg_grid_item_content">
								<div class="pg_grid_item_content_left">
									<div class="pg_grid_item_number">
										{{ statistics.actieAantalLesInschrijvingen.value }}
									</div>
								</div>
								<div class="pg_grid_item_content_right">
									<div class="pg_grid_item_description">
										Aantal leden die benaderd zijn voor groepslessen
									</div>
								</div>
							</div>
						</div>
						<div class="pg_grid_item" data-s-amount-item>
							<div class="pg_grid_item_heading">
								<div class="pg_grid_item_title s_elem-has-icon-left a_fill_default">
									Welkomst formulier
									<icon :icon-id="'icon_trainingsschema'"></icon>
								</div>
							</div>
							<div class="pg_grid_item_content">
								<div class="pg_grid_item_content_left">
									<div class="pg_grid_item_number">
										{{ statistics.actieAantalWelkomstformulieren.value }}
									</div>
								</div>
								<div class="pg_grid_item_content_right">
									<div class="pg_grid_item_description">
										Aantal welkomstformulieren die afgenomen zijn
									</div>
								</div>
							</div>
						</div>
					</div>

					<hr />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import KpiValue from "@/components/elements/KpiValue.vue";
import StatisticsBadgeCircle from "@/components/elements/StatisticsBadgeCircle.vue";
import { logger } from "@/logger";
import CancellationTargets from "./partials/CancellationTargets.vue";
import RegistrationTargets from "./partials/RegistrationTargets.vue";
import CheckIns from "./partials/checkIns.vue";

export default {
	name: "KpiDashboard",
	components: {
		StatisticsBadgeCircle,
		CheckIns,
		KpiValue,
		RegistrationTargets,
		CancellationTargets,
	},
	data() {
		return {
			statistics: {
				// GROESPLESSEN
				lesBezettingsgraad: { value: 0, prev: 0 },
				// COACHES INFORMATIE
				actieAantalIntakes: { value: 0, prev: 0 },
				actieAantalMetingen: { value: 0, prev: 0 },
				actieAantalDoelen: { value: 0, prev: 0 },
				actieAantalSchemas: { value: 0, prev: 0 },
				actieAantalLesInschrijvingen: { value: 0, prev: 0 },
				actieAantalWelkomstformulieren: { value: 0, prev: 0 },
				// GEZAMENLIJKE PRESTATIES VAN DE AFGELOPEN MAAND
				afgevallenGewicht: { value: 0, prev: 0 },
				gemiddeldGewicht: { value: 0, prev: 0 },
				gemiddeldeVerschilBmi: { value: 0, prev: 0 },
				gemiddeldeBmi: { value: 0, prev: 0 },
				gemiddeldeVerbeteringConditie: { value: 0, prev: 0 },
				gemiddeldeConditie: { value: 0, prev: 0 },
				gemiddeldeCalorieen: { value: 0, prev: 0 },
				totaalCalorieen: { value: 0, prev: 0 },
			},
			targets: {
				monthlyRegistrationTarget: { current: 0, target: 0 },
				yearlyRegistrationTarget: { current: 0, target: 0 },
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", ["selected"]),
	},
	watch: {
		query: {
			handler() {
				this.fetchStatistics();
			},
			deep: true,
		},
		"query.vergelijkMet": async function vergelijkMet() {
			// CHECK-INS PER UUR
			const result = await this.getVisitsPerHour(this.query).catch(logger.error);
			logger.log(result);
		},
	},
	mounted() {
		this.fetchStatistics();
	},
	methods: {
		...mapActions("dashboard", ["getVisitsPerHour"]),
		...mapActions("statistieken", ["getByPeriod", "getCompareByPeriod"]),
		getStatisticsByPeriod(soort, overwrite) {
			const data = { ...this.query, soort };
			if (overwrite) data.overWrittenPeriod = overwrite;
			this.getByPeriod(data)
				.then((result) => {
					this.statistics[_.camelCase(soort)].value = result.huidige.waarde;
					this.statistics[_.camelCase(soort)].prev = result.vorige.waarde;
				})
				.catch(logger.error);
		},
		async fetchStatistics() {
			// GROESPLESSEN
			// this.getCompareByPeriod({ ...this.query, soort: 'les-bezettingsgraad' }).then((result) => {
			//     this.statistics.lesBezettingsgraad = result.huidigeVestiging.waarde;
			// }).catch(logger.error);

			this.getStatisticsByPeriod("les-bezettingsgraad");
			// COACHES INFORMATIE
			this.getStatisticsByPeriod("actie-aantal-intakes");
			this.getStatisticsByPeriod("actie-aantal-metingen");
			this.getStatisticsByPeriod("actie-aantal-doelen");
			this.getStatisticsByPeriod("actie-aantal-schemas");
			this.getStatisticsByPeriod("actie-aantal-les-inschrijvingen");
			this.getStatisticsByPeriod("actie-aantal-welkomstformulieren");
			// GEZAMENLIJKE PRESTATIES VAN DE AFGELOPEN MAAND
			this.getStatisticsByPeriod("afgevallen-gewicht", "dag");
			this.getStatisticsByPeriod("gemiddeld-gewicht", "dag");
			this.getStatisticsByPeriod("gemiddelde-verschil-bmi", "dag");
			this.getStatisticsByPeriod("gemiddelde-bmi", "dag");
			this.getStatisticsByPeriod("gemiddelde-verbetering-conditie", "dag");
			this.getStatisticsByPeriod("gemiddelde-conditie", "dag");
			this.getStatisticsByPeriod("gemiddelde-calorieen", "dag");
			this.getStatisticsByPeriod("totaal-calorieen", "dag");
		},
	},
};
</script>

<style lang="scss" scoped></style>
