<template>
	<div class="pg_kpis_circle_item">
		<div class="pg_kpis_no">{{ statistics.current }}</div>
		<div class="pg_kpis_label">
			<div class="pg_kpis_label_text">{{ title }}</div>
			<div
				v-show="statistics.before !== 0 && statistics.current !== 0"
				class="pg_deviation a_margin-right-10"
				:class="changeColorClass"
			>
				{{ statistics.compare | valueFormatter }}%
			</div>
		</div>
		<div class="pg_kpis_label-before">{{ kpiLabelBefore }}</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	components: {
		Loader,
	},
	props: {
		soort: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		invertChangeColors: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			statistics: {
				current: 0,
				compare: 0,
				before: 0,
			},
			loading: false,
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { selectedBranch: "selected" }),
		kpiLabelBefore() {
			const period =
				this.query.vergelijkMet === "vorig-jaar"
					? "jaar"
					: this.query.periodType === "normal"
						? this.query.periode
						: `${this.query.days} dagen`;
			const previousText = period === "jaar" ? "vorig" : "vorige";
			return `${previousText} ${period}: ${this.statistics.before}`;
		},
		changeColorClass() {
			// By default an increase in value will be shown in green and a decrease in red.
			if (this.invertChangeColors) {
				if (this.statistics.compare >= 0) {
					return ["v_negative"];
				}
				return ["v_positive"];
			}
			if (this.statistics.compare >= 0) {
				return ["v_positive"];
			}
			return ["v_negative"];
		},
	},
	watch: {
		query: {
			handler() {
				this.fetchStatistics();
			},
			deep: true,
		},
	},
	mounted() {
		this.fetchStatistics();
	},
	methods: {
		...mapActions("statistieken", ["getByPeriod", "getCompareByPeriod"]),
		async fetchStatistics() {
			this.loading = true;
			this.statistics.current = 0;
			this.statistics.before = 0;
			this.statistics.compare = 0;
			await Promise.all([
				this.getByPeriod({ ...this.query, soort: this.soort }).then((result) => {
					this.statistics.current = result.huidige.waarde;
					this.statistics.before = result.vorige.waarde;
					this.statistics.compare =
						this.statistics.current === this.statistics.before
							? 0
							: ((this.statistics.current - this.statistics.before) /
									Math.abs(this.statistics.before)) *
								100;
				}),
				// this.getCompareByPeriod({ ...this.query, soort: this.soort }).then((result) => {
				//     this.statistics.compare = result.overigeVestigingen.waarde;
				// }),
			]).catch(logger.error);
			this.loading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 36px - 6px);
}
</style>
