<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_top">
					<div class="pg_header_top_left">
						<div class="pg_header_heading">
							<h2>Check-ins per uur</h2>

							<infoDropdown>
								Het aantal checkins van een dag vergeleken met een andere dag
							</infoDropdown>
						</div>
						<div class="pg_header_description">
							<p>Aantal check-ins per uur</p>
						</div>
					</div>
					<div class="pg_header_top_right">
						<div class="pg_period">
							<div class="pg_period_buttons">
								<button
									class="button v_transparent v_is-icon v_icon-size-20 a_margin-right-20 a_margin-bottom-0 a_no-padding"
									@click="
										date.subtract(1, 'days');
										counter--;
									"
								>
									<icon :icon-id="'icon_arrow-left'"></icon>
								</button>
								<div :key="counter" class="pg_period_current">
									{{ date.format("dddd D MMMM YYYY") }}
								</div>
								<button
									:disabled="counter >= 0"
									class="button v_transparent v_is-icon v_icon-size-20 a_margin-left-20 a_margin-bottom-0 a_no-padding"
									@click="
										date.add(1, 'days');
										counter++;
									"
								>
									<icon :icon-id="'icon_arrow-right'"></icon>
								</button>
							</div>

							<select v-model="vergelijkMet" class="v_transparent">
								<option value="vorige-periode">Vergelijk met vorige dag</option>
								<!-- <option value="week">Vergelijk met zelfde dag vorige week</option>
                                <option value="maand">Vergelijk met zelfde dag vorige maand</option> -->
								<option value="vorig-jaar">Vergelijk met dezelfde datum vorig jaar</option>
							</select>
						</div>
					</div>
				</div>
			</header>

			<br />

			<highcharts :options="chart"></highcharts>

			<hr />
		</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import InfoDropdown from "@/components/elements/InfoDropdown.vue";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	name: "KpiDashboard",
	components: {
		InfoDropdown,
		Loader,
	},
	data() {
		return {
			vergelijkMet: "vorige-periode",
			date: moment(),
			counter: 0,
			loading: false,
			chart: {
				colors: ["#d4145a", "#dadae3"],
				chart: {
					type: "column",
					marginTop: 60,
				},
				xAxis: {
					crosshair: {
						color: "#dadae3",
					},
					title: {
						text: "tijd",
					},
					type: "datetime",
					dateTimeLabelFormats: {
						hour: "%H:%M",
					},
					tickInterval: 3600 * 1000,
				},
				yAxis: {
					title: {
						text: "check-ins per uur",
					},
				},
				plotOptions: {
					series: {
						pointStart: Date.UTC(2017, 10, 1, 7),
						pointInterval: 3600 * 1000,
						borderWidth: 0,
					},
				},
				tooltip: {
					xDateFormat: "%H:%M",
				},
				series: [
					{
						name: "check-ins",
						data: [20, 30, 33, 45, 23, 32, 24, 45, 65, 67, 34, 65, 23, 54, 23, 34, 56],
						pointPlacement: 0.08,
						zIndex: 2,
					},
					{
						name: "check-ins, vergelijking",
						data: [65, 67, 34, 65, 23, 54, 20, 30, 33, 45, 23, 32, 24, 45, 23, 34, 24],
						pointPlacement: -0.08,
						zIndex: 1,
					},
				],
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { selectedBranch: "selected" }),
	},
	watch: {
		vergelijkMet() {
			this.load();
		},
		counter() {
			this.load();
		},
		selectedBranch: {
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	methods: {
		...mapActions("dashboard", ["getVisitsPerHour"]),
		...mapActions("statistieken", ["getByPeriod", "getCompareByPeriod"]),
		async load() {
			// CHECK-INS PER UUR
			try {
				this.loading = true;
				const result = await this.getVisitsPerHour({
					vergelijkMet: this.vergelijkMet,
					date: this.date,
				});
				let data = _.fill(Array(17), 0);
				if (_.isArray(result.bezoekenVandaag)) {
					result.bezoekenVandaag.forEach((item) => {
						data[item.uur - 7] = item.bezoeken;
					});
				}
				this.chart.series[0].data = data;
				data = _.fill(Array(17), 0);
				if (_.isArray(result.bezoekenVorigePeriode)) {
					result.bezoekenVorigePeriode.forEach((item) => {
						data[item.uur - 7] = item.bezoeken;
					});
				}
				this.chart.series[1].data = data;
				this.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 36px - 6px);
	left: 100%;
}
</style>
